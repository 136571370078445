import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import 'formdata-polyfill';
import { BasicObject } from '~components/ObjectHelper';
import { z } from 'zod';
import { rowSchema } from '~react/components/FilterList/utils/Item';

interface FormDataObject {
    [key: string]: string;
}

const redirectResponseSchema = z.object({
    type: z.literal('redirect'),
    redirectUrl: z.string(),
});

const modalResponseSchema = z.object({
    type: z.literal('modal'),
    htmlContent: z.string(),
});

const ajaxModalResponseSchema = z.object({
    type: z.literal('ajaxModal'),
    modalUrl: z.string(),
});

const messageResponseSchema = z.object({
    type: z.literal('message'),
    message: z.string(),
});

const gridDataResponseSchema = z.object({
    type: z.literal('gridData'),
    hasNext: z.boolean(),
    rows: z.array(rowSchema),
    footer: z.nullable(rowSchema),
});

const eventsResponseSchema = z.object({
    type: z.literal('events'),
    events: z.array(
        z.object({
            url: z.string(),
            color: z.string(),
            title: z.string(),
            start: z.date(),
            end: z.date(),
            classNames: z.array(z.string()),
        }),
    ),
});

const errorResponseSchema = messageResponseSchema.extend({
    type: z.literal('error'),
    reload: z.boolean(),
    fieldErrors: z.array(
        z.object({
            message: z.string(),
            fieldId: z.string(),
        }),
    ),
});

interface ResponseStatus {
    status: number;
}

interface ResponseCancelled {
    canceled: boolean;
}

type AjaxErrorResponse = z.infer<typeof errorResponseSchema>;
type AjaxResponse = z.infer<typeof responseSchema>;
export type AjaxHandlerResponse = AjaxResponse & ResponseStatus;
export type AjaxHandlerResponseError = AjaxErrorResponse & ResponseStatus & ResponseCancelled;

const responseSchema = z.discriminatedUnion('type', [
    errorResponseSchema,
    messageResponseSchema,
    redirectResponseSchema,
    gridDataResponseSchema,
    eventsResponseSchema,
    ajaxModalResponseSchema,
    modalResponseSchema,
]);

const createError = (error: AxiosError): AjaxHandlerResponseError => {
    const result: AjaxHandlerResponseError = {
        type: 'error',
        status: 0,
        canceled: false,
        message: 'Data fetching failed',
        reload: false,
        fieldErrors: [],
    };
    if (axios.isCancel(error)) {
        result.message = 'Data fetching cancelled';
        result.canceled = true;
        return result;
    }

    if (error.response) {
        result.status = error.response.status;
        if (error.response.data) {
            try {
                const data = errorResponseSchema.parse(error.response.data);

                result.message = data.message;
                result.fieldErrors = data.fieldErrors;
                result.reload = data.reload;
            } catch (parsingError) {
                console.error('Recieved unexpected error response data from api', {
                    data: error.response.data,
                    parsingError,
                });
            }
        }
    }

    return result;
};

const createSuccess = (response: AxiosResponse<unknown>): AjaxHandlerResponse => {
    return {
        status: response.status,
        ...responseSchema.parse(response.data),
    };
};

const AjaxHandler = (): {
    get: (
        url: string,
        params?: AxiosRequestConfig,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
    ) => void;
    post: (
        url: string,
        data?: FormData | BasicObject | string,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
        headers?: BasicObject,
    ) => void;
    put: (
        url: string,
        data?: FormData | BasicObject | string,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
        headers?: BasicObject,
    ) => void;
    deleteRequest: (
        url: string,
        data?: FormData | BasicObject | string,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
    ) => void;
    getFormData: (formElement: Element) => FormData;
    getData: (formData: FormData) => FormDataObject;
} => {
    const baseHeaders = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
    };

    const get = (
        url: string,
        params?: AxiosRequestConfig,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
    ): void => {
        params = undefined === params ? {} : params;

        // Set base headers
        params.headers = {
            ...baseHeaders,
            ...params.headers,
        };

        axios
            .get(url, params)
            .then((response): void => {
                if (!(handleSuccess instanceof Function)) {
                    return;
                }
                handleSuccess(createSuccess(response));
            })
            .catch(error => {
                if (!(handleError instanceof Function)) {
                    return;
                }
                handleError(createError(error));
            })
            .finally(() => {
                if (!(handleAlways instanceof Function)) {
                    return;
                }
                handleAlways();
            });
    };

    const post = (
        url: string,
        data?: BasicObject | string,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
        headers?: BasicObject,
    ): void => {
        // Set base headers
        if (undefined == headers) {
            headers = baseHeaders;
        }
        axios({
            method: 'post',
            url: url,
            data: undefined === data ? {} : data,
            headers: headers,
        })
            .then((response): void => {
                if (!(handleSuccess instanceof Function)) {
                    return;
                }
                handleSuccess(createSuccess(response));
            })
            .catch(error => {
                if (!(handleError instanceof Function)) {
                    return;
                }

                handleError(createError(error));
            })
            .finally(() => {
                if (!(handleAlways instanceof Function)) {
                    return;
                }
                handleAlways();
            });
    };

    const put = (
        url: string,
        data?: BasicObject | string,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
        headers?: BasicObject,
    ): void => {
        if (undefined == headers) {
            headers = baseHeaders;
        }
        // Set base headers
        axios({
            method: 'post',
            url: url,
            data: undefined === data ? {} : data,
            headers: {
                'X-HTTP-METHOD-OVERRIDE': 'PUT',
                ...headers,
            },
        })
            .then((response): void => {
                if (!(handleSuccess instanceof Function)) {
                    return;
                }
                handleSuccess(createSuccess(response));
            })
            .catch(error => {
                if (!(handleError instanceof Function)) {
                    return;
                }

                handleError(createError(error));
            })
            .finally(() => {
                if (!(handleAlways instanceof Function)) {
                    return;
                }
                handleAlways();
            });
    };

    const deleteRequest = (
        url: string,
        data?: BasicObject | string,
        handleSuccess?: (response: AjaxHandlerResponse) => void,
        handleError?: (error: AjaxHandlerResponseError) => void,
        handleAlways?: () => void,
    ): void => {
        // Set base headers
        axios({
            method: 'post',
            url: url,
            data: undefined === data ? {} : data,
            headers: {
                'X-HTTP-METHOD-OVERRIDE': 'DELETE',
                ...baseHeaders,
            },
        })
            .then((response): void => {
                if (!(handleSuccess instanceof Function)) {
                    return;
                }
                handleSuccess(createSuccess(response));
            })
            .catch(error => {
                if (!(handleError instanceof Function)) {
                    return;
                }

                handleError(createError(error));
            })
            .finally(() => {
                if (!(handleAlways instanceof Function)) {
                    return;
                }
                handleAlways();
            });
    };

    const getFormData = (formElement: Element): FormData => {
        return new FormData(formElement as HTMLFormElement);
    };

    const getData = (formData: FormData): FormDataObject => {
        const objectFormData: FormDataObject = {};
        formData.forEach((value, key) => {
            objectFormData[key] = String(value);
        });

        return objectFormData;
    };

    return {
        get: get,
        post: post,
        put: put,
        deleteRequest: deleteRequest,
        getFormData: getFormData,
        getData: getData,
    };
};

const ajaxHandler = AjaxHandler();

export { ajaxHandler };
