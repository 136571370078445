import React from 'react';
import SortingTableLabel from '~react/components/FilterList/components/SortingTableLabel';
import ActionButton from '~react/components/FilterList/components/ActionButton';
import SelectTableRow from '~react/components/FilterList/components/SelectTableRow';
import Item, { RowInterface } from '~react/components/FilterList/utils/Item';
import { FilterListConfigColumnInterface } from '~types/FilterListConfigInterface';
import { AjaxHandlerResponse, AjaxHandlerResponseError } from '~components/AjaxHandler';

interface ListTableProps {
    columns: FilterListConfigColumnInterface[];
    items: RowInterface[];
    footer: RowInterface | null;
    hasSelected: boolean;
    onSelectToggle: (id: string) => void;
    isItemSelected: (item: RowInterface[]) => boolean;
    getItemId: (item: RowInterface) => string;
    onSort: (by: string, type: string) => void;
    handleSuccess: (response: AjaxHandlerResponse) => void;
    handleError: (response: AjaxHandlerResponseError) => void;
    openModal: (url: string) => void;
    beforePost?: () => void;
    texts: {
        asc: string;
        desc: string;
        confirm: {
            label: string;
            yes: string;
            no: string;
        };
    };
}

const ListTable: React.FC<ListTableProps> = props => {
    const getLabelById = (id: string): string => {
        let ret = '';
        props.columns.map(column => {
            if (id === column.name) {
                ret = column.label;
                return;
            }
        });

        return ret;
    };

    const tHead = props.columns.map(column => (
        <th key={column.name} className={'undefined' === typeof column.classes ? '' : column.classes}>
            {column.sortable ? (
                <SortingTableLabel
                    label={column.label}
                    column={column.name}
                    onSort={props.onSort}
                    texts={props.texts}
                />
            ) : (
                column.label
            )}
        </th>
    ));

    const itemRow = (item: RowInterface) => {
        const columns: JSX.Element[] = [];

        // has a select option
        if (props.hasSelected && null !== item.actionsCell) {
            columns.push(
                <td key="select" rowSpan={item.actionsCell?.rowSpan ?? 1}>
                    <SelectTableRow
                        id={props.getItemId(item)}
                        checked={props.isItemSelected([item])}
                        onToggle={props.onSelectToggle}
                    />
                </td>,
            );
        }

        props.columns.forEach(column => {
            if (Item.isCellSkipped(item, column.name)) {
                return;
            }
            columns.push(
                <td
                    key={column.name}
                    className={Item.getClasses(item, column.name)}
                    style={Item.getCellStyle(item, column.name)}
                    rowSpan={Item.getCellRowSpan(item, column.name)}
                    dangerouslySetInnerHTML={{
                        __html: Item.getValue(item, column.name) as string,
                    }}
                    data-label={getLabelById(column.name)}
                />,
            );
        });

        // has actions
        const actionsData = item.actionsCell?.actions;

        if (actionsData !== undefined) {
            const actions: JSX.Element[] = [];

            actionsData.forEach((action, index) => {
                actions.push(
                    <ActionButton
                        key={index.toString()}
                        action={{
                            label: action.label,
                            url: action.url,
                            addSelected: false,
                            addFilter: false,
                            confirm: action.confirm,
                            method: action.method,
                            icon: action.icon,
                            modal: action.modal,
                        }}
                        texts={props.texts}
                        buttonType="icon-link"
                        disabled={false}
                        handleError={props.handleError}
                        beforePost={props.beforePost}
                        postData={action.postData}
                        handleSuccess={props.handleSuccess}
                        openModal={props.openModal}
                    />,
                );
            });
            if (null !== item.actionsCell) {
                columns.push(
                    <td key="actions" className="_no-wrap" rowSpan={item.actionsCell?.rowSpan}>
                        {actions}
                    </td>,
                );
            }
        }

        return columns;
    };

    const itemRows = props.items.map((item, key) => (
        <tr key={key} className={item.class}>
            {itemRow(item)}
        </tr>
    ));

    const footer =
        props.footer !== null ? (
            <tfoot>
                <tr>{itemRow(props.footer)}</tr>
            </tfoot>
        ) : null;

    return (
        <div className="table-overflow-wrapper">
            <div className="table-overflow">
                <table className="table -colored-light">
                    <thead>
                        <tr>
                            {props.hasSelected ? (
                                <th>
                                    <SelectTableRow
                                        id="checkAll"
                                        checked={props.isItemSelected(props.items)}
                                        onToggle={props.onSelectToggle}
                                    />
                                </th>
                            ) : null}
                            {tHead}
                        </tr>
                    </thead>
                    <tbody>{itemRows}</tbody>
                    {footer}
                </table>
            </div>
        </div>
    );
};

export default ListTable;
