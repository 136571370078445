const BurgerMenu = (): { init: () => void } => {
    const init = () => {
        const burgerButtonElement: null | HTMLElement = document.querySelector('.jsBurgerButton');
        const burgerNavigationElement: null | HTMLElement = document.querySelector('.jsBurgerNav');

        if (null == burgerButtonElement || null === burgerNavigationElement) {
            return;
        }

        burgerButtonElement.addEventListener('click', () => {
            burgerNavigationElement.classList.toggle('-open');
            burgerButtonElement.classList.toggle('-open');
        });
    };

    return {
        init,
    };
};

export default BurgerMenu;
