import React from 'react';
import ActionButton from '~react/components/FilterList/components/ActionButton';
import { FilterListConfigListActionInterface } from '~types/FilterListConfigInterface';
import { AjaxHandlerResponse, AjaxHandlerResponseError } from '~components/AjaxHandler';
import { BasicObject } from '~components/ObjectHelper';
import { FilterListFilterInterface } from '~types/FilterListFilterInterface';

interface ListActionsProps {
    actions: FilterListConfigListActionInterface[];
    include?: string[];
    exclude?: string[];
    group: boolean;
    buttonType?: 'link' | 'secondary' | 'primary';
    disabled: boolean;
    handleSuccess: (response: AjaxHandlerResponse) => void;
    handleError: (response: AjaxHandlerResponseError) => void;
    openModal: (url: string) => void;
    beforePost?: () => void;
    postData: BasicObject;
    filter: FilterListFilterInterface | undefined;
    texts: {
        confirm: {
            label: string;
            yes: string;
            no: string;
        };
    };
}

const ListActions: React.FC<ListActionsProps> = props => {
    const buttons = props.actions
        .filter(
            action =>
                ('undefined' !== typeof props.exclude && !props.exclude.includes(action.action)) ||
                ('undefined' !== typeof props.include && props.include.includes(action.action)),
        )
        .map((action, index) => (
            <ActionButton
                key={action.action + index}
                action={action}
                buttonType={'undefined' === typeof props.buttonType ? 'secondary' : props.buttonType}
                handleSuccess={props.handleSuccess}
                handleError={props.handleError}
                beforePost={props.beforePost}
                postData={props.postData}
                disabled={action.addSelected && props.disabled}
                texts={props.texts}
                openModal={props.openModal}
                filter={props.filter}
            />
        ));

    return buttons.length ? <div className={props.group ? 'form__buttons' : ''}>{buttons}</div> : null;
};

export default ListActions;
