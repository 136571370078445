import React from 'react';

interface ListInfoProps {
    message: string;
}

const ListInfo: React.FC<ListInfoProps> = props => {
    return '' === props.message ? null : <div className="_text-align-center">{props.message}</div>;
};

export default ListInfo;
