interface FormattingResult {
    fields: {
        field: Element;
        oldValue: string;
        newValue: string;
    }[];
}

const Formatter = (): {
    formatter: {
        checkAll: (formElement: HTMLFormElement) => FormattingResult;
        checkField: (targetInputElement: Element) => { oldValue: string; newValue: string };
        formatField: (value: string, formatType: string) => string;
        filterFormElements: (fields: HTMLFormControlsCollection) => Element[];
    };
    formatInput: (inputElement: Element) => void;
    formatForm: (formElement: Element) => void;
    initForms: () => void;
} => {
    const formatter = {
        checkField: (targetInputElement: Element): { oldValue: string; newValue: string } => {
            const formatType: string | null = targetInputElement.getAttribute('data-format-type');
            const { value: oldValue } = targetInputElement as HTMLInputElement;

            let newValue = oldValue;
            if (null !== formatType) {
                newValue = formatter.formatField(oldValue, formatType);
            }

            return {
                oldValue,
                newValue,
            };
        },
        formatField: (value: string, formatType: string): string => {
            switch (formatType) {
                case 'phoneNumber': {
                    let newValue = value.replace(/ /g, ''); // Replace spaces
                    newValue = newValue.replace(/^\+420/, '');
                    newValue = newValue.replace(/^(00|\+)\d*(\d{9})/, '$2'); // Keep only last 9 digits
                    return newValue;
                }
            }

            return value;
        },
        filterFormElements: (fields: HTMLFormControlsCollection): Element[] => {
            const fieldsToCheck: Element[] = [];

            [...fields].forEach((field: Element) => {
                const isAllowedElement = field.nodeName.match(/input|textarea/gi);
                const isRequiredAttrib = field.hasAttribute('required');
                const isDisabled = field.hasAttribute('disabled');
                const isOptional = field.className.indexOf('optional') != -1;

                if (isAllowedElement && (isRequiredAttrib || isOptional) && !isDisabled) {
                    fieldsToCheck.push(field);
                }
            });

            return fieldsToCheck;
        },
        checkAll: (formElement: HTMLFormElement): FormattingResult => {
            const result: FormattingResult = {
                fields: [],
            };

            const fieldsToCheck = formatter.filterFormElements(formElement.elements);

            fieldsToCheck.forEach((field: Element) => {
                const fieldData = formatter.checkField(field);

                if (fieldData.newValue === fieldData.oldValue) {
                    return;
                }

                result.fields.push({
                    field,
                    ...fieldData,
                });
            });

            return result;
        },
    };

    const updateInput = (inputElement: Element, value: string): void => {
        (inputElement as HTMLInputElement).value = value;
    };

    const formatInput = (inputElement: Element): void => {
        const formatResult = formatter.checkField(inputElement);

        if (formatResult.newValue === formatResult.oldValue) {
            return;
        }

        updateInput(inputElement, formatResult.newValue);
    };

    const checkInputInit = (inputElement: Element): void => {
        inputElement.addEventListener('blur', event => {
            const targetInputElement = event.target as HTMLInputElement;
            formatInput(targetInputElement);
            targetInputElement.dispatchEvent(new Event('change', { bubbles: true }));
        });
    };

    const formatForm = (formElement: Element): void => {
        const formatterResult = formatter.checkAll(formElement as HTMLFormElement);
        formatterResult.fields.forEach((field: { field: Element; oldValue: string; newValue: string }) => {
            updateInput(field.field, field.newValue);
        });
    };

    const initForm = (formElement: Element): void => {
        formElement.addEventListener('submit', event => {
            formatForm(event.target as Element);
        });
    };

    const initForms = (): void => {
        const formsElements: Element[] = [...document.querySelectorAll('.jsFormFormatter')];
        const formWatchedElements: Element[] = [...document.querySelectorAll('[data-format-watch="1"]')];

        if (0 === formsElements.length) {
            return;
        }

        formsElements.forEach((formElement: Element) => {
            initForm(formElement);
        });

        if (0 === formWatchedElements.length) {
            return;
        }

        formWatchedElements.forEach((formWatchedElement: Element) => {
            checkInputInit(formWatchedElement);
        });
    };

    return {
        initForms: initForms,
        formatForm: formatForm,
        formatInput: formatInput,
        formatter: formatter,
    };
};

const formatter = Formatter();

export { formatter };

document.addEventListener('DOMContentLoaded', () => {
    formatter.initForms();
});
