import { render } from 'react-dom';
import { getJsonDataFromDataAttribute } from '~utils/json';
import FilterList from '../react/components/FilterList';
import React from 'react';

const InitFilterList = () => {
    const elements: Element[] = [...document.querySelectorAll('.js_filterList')];

    if (0 === elements.length) {
        return;
    }

    elements.map(element => {
        render(<FilterList config={getJsonDataFromDataAttribute(element.getAttribute('data-config'))} />, element);
    });
};

export default InitFilterList;
