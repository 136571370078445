import React from 'react';
import { FilterListConfigFilterCellInterface, FilterListConfigPagerInterface } from '~types/FilterListConfigInterface';
import DateSelect from '../../../atoms/DateSelect';
import { getFormattedDate } from '~utils/date';
import { BasicObject } from '~components/ObjectHelper';
import MonthSelect from '../../../atoms/MonthSelect';
import { getLocaleFromHtml } from '~utils/locale';
import FilterTextInput from '~react/components/FilterList/components/FilterTextInput';
import PerPageSelect from '~react/components/FilterList/components/PerPageSelect';

interface FilterProps {
    cells: FilterListConfigFilterCellInterface[];
    values: BasicObject;
    onChange: (name: string, value: string, reload: boolean) => void;
    onPerPageChange: (perPage: number) => void;
    pager: FilterListConfigPagerInterface | null;
}

const Filter: React.FC<FilterProps> = props => {
    if (undefined === props.cells) {
        return null;
    }

    const getReload = (value: string, config: string): boolean => {
        return !(
            ('' !== value && 'ON_EMPTY' === config) ||
            ('' === value && 'ON_NOT_NULL' === config) ||
            'NONE' === config
        );
    };

    const filterInputs = props.cells.map((input, index) => {
        let inputElement = <></>;

        switch (input.type) {
            case 'text':
                inputElement = (
                    <FilterTextInput
                        key={input.name + index}
                        id={input.name}
                        defaultValue={props.values[input.name]}
                        onChange={value => {
                            props.onChange(input.name, value, false);
                        }}
                    />
                );
                break;
            case 'select':
                const options: JSX.Element[] = [];
                if (undefined !== input.options) {
                    input.options.map(({ value, label }) => {
                        options.push(
                            <option value={value} key={value}>
                                {label}
                            </option>,
                        );
                    });
                }
                inputElement = (
                    <div className="custom-select" key={input.name + index}>
                        <select
                            value={props.values[input.name]}
                            onChange={event => {
                                props.onChange(
                                    input.name,
                                    event.currentTarget.value,
                                    getReload(event.currentTarget.value, input.reloadActionType),
                                );
                            }}
                        >
                            {options}
                        </select>
                    </div>
                );
                break;
            case 'date':
                return (
                    <div className="filter-date-select" key={input.name + index}>
                        <DateSelect
                            value={props.values[input.name]}
                            name={input.name}
                            id={input.name}
                            label={input.label}
                            onDateChange={date => {
                                props.onChange(input.name, date !== null ? getFormattedDate(date) : '', false);
                            }}
                            container={true}
                            texts={{ calendar: input.label }}
                            locale={getLocaleFromHtml()}
                        />
                    </div>
                );
            case 'checkbox':
                return (
                    <div className="form__cell" key={input.name + index}>
                        <div className="checkbox__cell">
                            <input
                                type="checkbox"
                                id={input.name}
                                name={input.name}
                                checked={'1' === props.values[input.name]}
                                onChange={event => {
                                    props.onChange(
                                        input.name,
                                        (event.currentTarget as HTMLInputElement).checked ? '1' : '0',
                                        false,
                                    );
                                }}
                            />
                            <label htmlFor={input.name} className="form__label">
                                {input.label}
                            </label>
                        </div>
                    </div>
                );
            case 'months':
                return (
                    <MonthSelect
                        key={input.name + index}
                        value={new Date(Date.parse(props.values[input.name]))}
                        label={input.label}
                        id={input.name}
                        startDate={
                            'undefined' !== typeof input.from && input.from !== null
                                ? new Date(Date.parse(input.from))
                                : undefined
                        }
                        endDate={
                            'undefined' !== typeof input.to && input.to !== null
                                ? new Date(Date.parse(input.to))
                                : undefined
                        }
                        onChange={date => {
                            props.onChange(input.name, date, false);
                        }}
                    />
                );
        }

        return (
            <div className="form__cell" key={input.name}>
                <label className="form__label" htmlFor={input.name}>
                    {input.label}
                </label>
                {inputElement}
            </div>
        );
    });

    return 0 === props.cells.length ? null : (
        <div className="filter -rounded">
            {filterInputs}
            {props.pager ? (
                <PerPageSelect
                    perPageOptions={props.pager.perPageOptions}
                    label={props.pager.perPageLabel}
                    value={props.values.perPage}
                    onChange={props.onPerPageChange}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default Filter;
