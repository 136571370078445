import { render } from 'react-dom';
import React from 'react';
import ListModal from '../react/atoms/ListModal';

const Modal = (): {
    modalInit: () => void;
    modalOpen: (url: string, afterOpened?: () => void) => void;
    modalHtmlOpen: (content: string, afterOpened?: () => void) => void;
} => {
    const getModalElement = (): Element => {
        let element = document.getElementById('openedModal');
        if (element === null) {
            element = document.createElement('div');
            element.setAttribute('id', 'openedModal');
            document.body.appendChild(element);
        }
        return element;
    };

    const modalHtmlOpen = (content: string, afterOpened?: () => void): void => {
        render(<ListModal sourceURL="" content={content} isOpen={true} afterOpened={afterOpened} />, getModalElement());
    };

    const modalOpen = (url: string, afterOpened?: () => void): void => {
        render(<ListModal sourceURL={url} isOpen={true} afterOpened={afterOpened} />, getModalElement());
    };

    const openImmediatelyModal = (): void => {
        const immediatelyModal: Element | null = document.querySelector('#immediately-modal');

        if (null !== immediatelyModal) {
            const immediatelyModalUrl: string | null = immediatelyModal.getAttribute('data-open-modal-immediately-url');
            if (null !== immediatelyModalUrl) {
                modalOpen(immediatelyModalUrl);
            }
        }
    };

    const modalInit = (): void => {
        document.addEventListener('modalCloseEnd', () => {
            const modalElement = document.querySelector('#openedModal');
            if (null === modalElement) {
                return;
            }

            modalElement.remove();
        });

        const elements: Element[] = [...document.querySelectorAll('.js_modal')];

        if (0 === elements.length) {
            return;
        }

        elements.map(element => {
            const url = (element as HTMLAnchorElement).getAttribute('href');

            if (null === url) {
                return;
            }

            element.addEventListener('click', event => {
                event.preventDefault();

                modalOpen(url);
            });
        });

        openImmediatelyModal();
    };
    return {
        modalInit: modalInit,
        modalOpen: modalOpen,
        modalHtmlOpen: modalHtmlOpen,
    };
};

const modal = Modal();

export default modal;
