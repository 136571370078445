import React from 'react';

interface MessageProps {
    messageType: 'error' | 'success';
    message: string;
}

const Message: React.FC<MessageProps> = props => {
    return '' === props.message ? null : (
        <div className={'alert -' + props.messageType} role="alert">
            <p>{props.message}</p>
        </div>
    );
};

export default Message;
