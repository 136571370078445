import React, { useState } from 'react';

interface MultipleFileInputProps {
    texts: {
        delete: string;
        addNew: string;
        note: string;
    };
    accept: string | null;
    required: boolean;
}

const MultipleFileInput: React.FC<MultipleFileInputProps> = props => {
    const [values, setValues] = useState<string[]>([]);

    const setValue = (index: number, value: string) => {
        const newValues = values;

        newValues[index] = value;

        setValues([...newValues]);
    };

    const removeValue = (index: number) => {
        const newValues = values;

        delete newValues[index];

        setValues([...newValues]);
    };

    const rows = values.map((value, index) => {
        if (undefined === value) {
            return;
        }
        return (
            <tr key={index}>
                <td>
                    <input
                        name={'image.' + index}
                        type="file"
                        defaultValue={value}
                        onChange={e => {
                            setValue(index, e.currentTarget?.value);
                        }}
                        accept={null === props.accept ? '' : props.accept}
                        required={props.required}
                    />
                </td>
                <td>
                    <small>{props.texts.note}</small>
                </td>
                <td className="-icon">
                    <button
                        className="btn -link -with-icon"
                        type="button"
                        onClick={() => {
                            removeValue(index);
                        }}
                    >
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="32"
                            viewBox="0 0 19 32"
                        >
                            <title>{props.texts.delete}</title>
                            <path
                                fill="#C80019"
                                d="M19.2 25.2h-1.131l-8.469-8.469-8.469 8.469h-1.131v-1.131l8.469-8.469-8.469-8.469v-1.131h1.131l8.469 8.469 8.469-8.469h1.131v1.131l-8.469 8.469 8.469 8.469v1.131z"
                            />
                        </svg>
                        <span>{props.texts.delete}</span>
                    </button>
                </td>
            </tr>
        );
    });

    const addNewInput = () => {
        setValues([...values, '']);
    };

    return (
        <>
            <table className="multiple-file table -with-inputs">
                <tbody>{rows}</tbody>
            </table>
            <p>
                <button className="btn -link -with-icon" type="button" onClick={addNewInput}>
                    <svg
                        aria-hidden="true"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                    >
                        <path d="M14.4 6h1.6v17.6h-1.6v-17.6zM24 14v1.6h-17.6v-1.6h17.6z"></path>
                    </svg>
                    {props.texts.addNew}
                </button>
            </p>
        </>
    );
};

export default MultipleFileInput;
