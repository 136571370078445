import React, { ChangeEvent } from 'react';
import { getFormattedIsoMonthDate, localizeMonth } from '~utils/date';
import { getLocaleFromHtml } from '~utils/locale';

interface MonthSelectProps {
    value: Date;
    label: string;
    id: string;
    onChange: (date: string) => void;
    startDate?: Date;
    endDate?: Date;
}

const MonthSelect: React.FC<MonthSelectProps> = props => {
    const locale: 'en' | 'cs' = getLocaleFromHtml();
    const getFormattedMonth = (date: Date): string => {
        return localizeMonth[locale][date.getMonth()] + ' ' + date.getFullYear();
    };

    const getMonthsCount = (date: Date): number => {
        return date.getFullYear() * 12 + date.getMonth();
    };

    const getMonthOptions = (startDate?: Date, endDate?: Date, value?: Date): React.ReactNode[] => {
        const now = new Date();
        startDate = startDate ?? new Date(now.getFullYear() - 1, now.getMonth());
        endDate = endDate ?? new Date(now.getFullYear() + 1, now.getMonth());
        let rows: Array<React.ReactNode> = [];
        if (value && getMonthsCount(value) < getMonthsCount(startDate)) {
            rows.push(
                <option key={getFormattedIsoMonthDate(value)} value={getFormattedIsoMonthDate(value)}>
                    {getFormattedMonth(value)}
                </option>,
            );
        }
        let currentDate = new Date(startDate);
        while (getMonthsCount(currentDate) < getMonthsCount(endDate)) {
            rows.push(
                <option key={getFormattedIsoMonthDate(currentDate)} value={getFormattedIsoMonthDate(currentDate)}>
                    {getFormattedMonth(currentDate)}
                </option>,
            );
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        if (value && getMonthsCount(value) > getMonthsCount(endDate)) {
            rows.push(
                <option key={getFormattedIsoMonthDate(value)} value={getFormattedIsoMonthDate(value)}>
                    {getFormattedMonth(value)}
                </option>,
            );
        }
        return rows;
    };

    const onChangeMonth = (event: ChangeEvent): void => {
        props.onChange((event.currentTarget as HTMLInputElement).value);
    };

    const onLinkClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        props.onChange((event.currentTarget as HTMLElement).dataset['date'] as string);
    };

    let value = props.value;
    if (!isNaN(props.value.getTime())) {
        value.setDate(1);
    }
    const prevValue = new Date(value);
    const nextValue = new Date(value);

    prevValue.setMonth(prevValue.getMonth() - 1);
    nextValue.setMonth(nextValue.getMonth() + 1);

    return (
        <div className="form__cell -months">
            <button
                className="btn -link -left-arrow -white"
                onClick={onLinkClick}
                data-date={getFormattedIsoMonthDate(prevValue)}
            >
                <span className="text">{getFormattedMonth(prevValue)}</span>
            </button>
            <label className="form__label _visually-hidden" htmlFor={props.id}>
                {props.label}
            </label>
            <div className="custom-select">
                <select name={props.id} id={props.id} onChange={onChangeMonth} value={getFormattedIsoMonthDate(value)}>
                    {getMonthOptions(props.startDate, props.endDate, value)}
                </select>
            </div>
            <button
                className="btn -link -right-arrow -white"
                onClick={onLinkClick}
                data-date={getFormattedIsoMonthDate(nextValue)}
            >
                <span className="text">{getFormattedMonth(nextValue)}</span>
            </button>
        </div>
    );
};

export default MonthSelect;
