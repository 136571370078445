import React from 'react';

interface ListPagerInterface {
    page: number;
    hasNext: boolean;
    setPage: (page: number) => void;
    texts: {
        prevLabel: string;
        nextLabel: string;
    };
}

const ListPager: React.FC<ListPagerInterface> = props => {
    const pager = () => {
        return (
            <ul className="pager">
                {1 < props.page ? (
                    <li>
                        <a
                            href="#"
                            className="pager__item -prev btn -link -left-arrow"
                            onClick={event => {
                                event.preventDefault();
                                props.setPage(props.page - 1);
                            }}
                        >
                            {props.texts.prevLabel}
                        </a>
                    </li>
                ) : null}
                {props.hasNext ? (
                    <li>
                        <a
                            href="#"
                            className="pager__item -next btn -link -right-arrow"
                            onClick={event => {
                                event.preventDefault();
                                props.setPage(Number(props.page) + 1);
                            }}
                        >
                            {props.texts.nextLabel}
                        </a>
                    </li>
                ) : null}
            </ul>
        );
    };

    return <nav>{pager()}</nav>;
};

export default ListPager;
