// @ts-ignore
import FormValidator from '@yaireo/validator';
import { formatter } from '~components/Formatter';
import { AjaxValidatorItem } from '~types/AjaxValidatorTypes';
import { getLocaleFromHtml } from '~utils/locale';

const Validator = (): {
    validateForm: (formElement: Element) => boolean;
    processAjaxValidatorData: (data: Array<AjaxValidatorItem>, formId: string | null) => void;
    initForms: () => void;
} => {
    const validator = {
        externalValidator: new FormValidator({
            alerts: true,
            classes: {
                item: 'validator',
                alert: 'alert',
                bad: 'error',
            },
            texts:
                'cs' === getLocaleFromHtml()
                    ? {
                          invalid: 'pattern',
                          empty: 'Toto pole je povinné',
                          email: 'Zadejte e-mailovou adresu ve správném formátu, např. jannovak@seznam.cz',
                          password_repeat: 'Zadaná hesla se neshodují',
                          email_repeat: 'Zadané e-maily se neshodují',
                          number_min: 'Hodnota je příliš nízká',
                          number_max: 'Hodnota je příliš vysoká',
                          number: 'Vstup není číslo',
                          checked: 'Toto políčko musí být zaškrtnuté',
                      }
                    : {
                          invalid: 'pattern',
                          empty: 'This field is required',
                          email: 'Enter your e-mail address in the correct format, eg jannovak@seznam.cz',
                          password_repeat: 'The passwords you entered do not match',
                          email_repeat: 'The emails you entered do not match',
                          number_min: 'The value is too low',
                          number_max: 'The value is too high',
                          number: 'Value is not a number',
                          checked: 'This field must be checked',
                      },
        }),
        isValidPassword: (targetInputElement: Element): boolean => {
            const value: string = (targetInputElement as HTMLInputElement).value;

            return value.length >= 12;
        },
        isValidRequired: (targetInputElement: Element): boolean => {
            const value: string = (targetInputElement as HTMLInputElement).value;

            return value.length > 0;
        },
        isValidPhoneNumber: (targetInputElement: Element): boolean => {
            let value = (targetInputElement as HTMLInputElement).value;
            value = formatter.formatter.formatField(value, 'phoneNumber');
            const match = targetInputElement.getAttribute('data-validate-pattern');

            if (null === match) {
                return false;
            }
            return null !== value.match(match);
        },
        isValidGroupEmpty: (targetInputElement: Element): boolean => {
            const group = targetInputElement.getAttribute('data-validate-group');
            let valid = true;

            if (null === group) {
                return valid;
            }

            const groupElements = document.querySelectorAll(`[data-validate-group="${group}"]`);

            let value = '';

            groupElements.forEach(groupElement => {
                value += (groupElement as HTMLInputElement).value;
            });

            if ('' === value) {
                groupElements.forEach(groupElement => {
                    validator.removeInputMessages(groupElement);
                });
                return valid;
            }

            groupElements.forEach(groupElement => {
                if ('' === (groupElement as HTMLInputElement).value) {
                    validator.insertInputError(groupElement, '');
                    valid = false;
                } else if (!groupElement.hasAttribute('data-error-custom')) {
                    validator.removeInputMessages(groupElement);
                }
            });

            return valid;
        },
        removeInputMessages: (inputElement: Element): void => {
            let formCellElement: null | Element = inputElement.parentElement;

            if (null !== formCellElement && !formCellElement.classList.contains('form__cell')) {
                formCellElement = formCellElement.parentElement;
            }

            if (null !== formCellElement && formCellElement.classList.contains('react-datepicker-wrapper')) {
                formCellElement = formCellElement.parentElement;
            }

            inputElement.classList.remove('-error');

            if (null === formCellElement) {
                return;
            }

            const alertMessageElements: Element[] = [
                ...formCellElement.querySelectorAll('.form-input-error, .form-input-success'),
            ];

            if (0 === alertMessageElements.length) {
                return;
            }

            alertMessageElements.forEach(alertMessageElement => {
                if (null === formCellElement) {
                    return;
                }
                formCellElement.removeChild(alertMessageElement);
            });
        },
        insertInputError: (errorInputElement: Element, errorText: string): void => {
            const errorAlertElement = document.createElement('span');
            let formCellElement = errorInputElement.parentElement;
            let errorInputText = errorText;

            if (null !== formCellElement && !formCellElement.classList.contains('form__cell')) {
                formCellElement = formCellElement.parentElement;
            }

            if ('hidden' === errorInputElement.getAttribute('type')) {
                return;
            }

            if ('pattern' === errorText) {
                errorInputText = errorInputElement.getAttribute('data-error-pattern') as string;
            }

            errorInputElement.classList.add('-error');
            errorAlertElement.classList.add('form-input-error');
            errorAlertElement.innerHTML = errorInputText;

            if (null === formCellElement) {
                return;
            }

            if (formCellElement.classList.contains('react-datepicker-wrapper')) {
                formCellElement = formCellElement.parentElement;
            }

            if (null === formCellElement) {
                return;
            }

            formCellElement.append(errorAlertElement);
        },
        isValid: (targetInputElement: Element, type: string): boolean => {
            switch (type) {
                case 'password':
                    return validator.isValidPassword(targetInputElement);
                case 'phoneNumber':
                    return validator.isValidPhoneNumber(targetInputElement);
                case 'groupEmpty':
                    return validator.isValidGroupEmpty(targetInputElement);
                case 'checkbox':
                    return (targetInputElement as HTMLInputElement).checked;
            }

            return true;
        },
        checkField: (targetInputElement: Element): { valid: boolean; error: string } => {
            const validationType: string | null = targetInputElement.getAttribute('data-validate-type');
            const validationCustomError: string | null = targetInputElement.getAttribute('data-error-custom');
            const validatePriority: string | null = targetInputElement.getAttribute('data-validate-multiple-priority');
            const isRequired: string | null = targetInputElement.getAttribute('required');
            const isValidRequired: boolean = validator.isValidRequired(targetInputElement);

            if (null !== isRequired && !isValidRequired) {
                return {
                    valid: isValidRequired,
                    error: validator.externalValidator.texts.empty,
                };
            }

            const defaultValidResult = validator.externalValidator.checkField(targetInputElement);

            if (null === validationType || null === validationCustomError) {
                return defaultValidResult;
            }

            const customValidResult = {
                valid: validator.isValid(targetInputElement, validationType),
                error: validationCustomError,
            };

            if (null === validatePriority) {
                return customValidResult;
            }

            if ('custom' === validatePriority) {
                if (customValidResult.valid) {
                    return defaultValidResult;
                }

                return customValidResult;
            }

            if (defaultValidResult.valid) {
                return customValidResult;
            }

            return defaultValidResult;
        },
        filterFormElements: (fields: HTMLFormControlsCollection): Element[] => {
            const fieldsToCheck: Element[] = [];

            [...fields].forEach((field: Element) => {
                const isAllowedElement = field.nodeName.match(/input|textarea|select/gi);
                const isRequiredAttrib = field.hasAttribute('required');
                const isDisabled = field.hasAttribute('disabled');
                const isOptional = field.className.indexOf('optional') != -1;

                if (isAllowedElement && (isRequiredAttrib || isOptional) && !isDisabled) {
                    fieldsToCheck.push(field);
                }
            });

            return fieldsToCheck;
        },
        checkAll: (
            formElement: HTMLFormElement,
        ): {
            valid: boolean;
            fields: {
                field: Element;
                error: string;
                valid: boolean;
            }[];
        } => {
            const result: {
                valid: boolean;
                fields: {
                    field: Element;
                    error: string;
                    valid: boolean;
                }[];
            } = {
                valid: true,
                fields: [],
            };

            const fieldsToCheck = validator.filterFormElements(formElement.elements);

            fieldsToCheck.forEach((field: Element) => {
                const fieldData = validator.checkField(field);

                result.valid = fieldData.valid && result.valid;

                result.fields.push({
                    field,
                    error: fieldData.error,
                    valid: fieldData.valid,
                });
            });

            return result;
        },
    };

    const insertInputSuccess = (successInputElement: Element, successText: string): void => {
        const successAlertElement = document.createElement('span');
        let formCellElement = successInputElement.parentElement;

        if (null !== formCellElement && !formCellElement.classList.contains('form-cell')) {
            formCellElement = formCellElement.parentElement;
        }

        successAlertElement.classList.add('form-input-success');
        successAlertElement.innerHTML = successText;

        if (null === formCellElement) {
            return;
        }
        formCellElement.insertBefore(successAlertElement, formCellElement.childNodes[0]);
    };

    const checkLinkedIsValid = (inputElement: Element): boolean => {
        const linkedId: null | string = inputElement.getAttribute('data-validate-linked');

        if (null === linkedId) {
            return true;
        }

        const linkedElement: null | Element = document.querySelector(`[name=${linkedId}]`);

        if (null !== linkedElement) {
            return linkedElement.classList.contains('valid');
        }

        return true;
    };

    const clearInputInit = (inputElement: Element): void => {
        const clearInputName: null | string = inputElement.getAttribute('data-validate-clear');

        if (null === clearInputName) {
            return;
        }

        const clearInputElement: null | Element = document.querySelector(`[name=${clearInputName}]`);

        if (null === clearInputElement) {
            return;
        }

        clearInputElement.addEventListener('keyup', () => {
            (inputElement as HTMLInputElement).value = '';
            validator.removeInputMessages(inputElement);
        });
    };

    const checkInputInit = (inputElement: Element): void => {
        const onInputUpdate = (event: Event): void => {
            const targetInputElement = event.target as Element;
            validator.removeInputMessages(targetInputElement);
            const validatorResult = validator.checkField(targetInputElement);
            const checkLinked = checkLinkedIsValid(targetInputElement);

            // is not valid
            if (!validatorResult.valid && checkLinked) {
                targetInputElement.classList.remove('valid');
                validator.insertInputError(targetInputElement, validatorResult.error);
                return;
            }

            // is valid
            const successText: null | string = targetInputElement.getAttribute('data-success-message');

            if (null !== successText && checkLinked) {
                insertInputSuccess(targetInputElement, successText);
            }
            targetInputElement.classList.remove('error');
            targetInputElement.classList.add('valid');
        };

        inputElement.addEventListener('keyup', onInputUpdate);
        inputElement.addEventListener('change', onInputUpdate);

        clearInputInit(inputElement);

        if ('groupEmpty' === inputElement.getAttribute('data-validate-type')) {
            const group = inputElement.getAttribute('data-validate-group');

            if (null === group) {
                return;
            }

            const groupElements = document.querySelectorAll(`[data-validate-group="${group}"]`);

            groupElements.forEach(groupElement => {
                groupElement.addEventListener('keyup', () => {
                    inputElement.dispatchEvent(new Event('change'));
                });
            });
        }
    };

    const validateForm = (formElement: Element): boolean => {
        const validatorResult = validator.checkAll(formElement as HTMLFormElement);
        let scrolled = false;

        if (!validatorResult.valid) {
            validatorResult.fields.forEach((field: { valid: boolean; field: Element; error: string }) => {
                validator.removeInputMessages(field.field);
                if (!field.valid) {
                    validator.insertInputError(field.field, field.error);
                    checkInputInit(field.field);
                    if (!scrolled) {
                        field.field.scrollIntoView();
                        scrolled = true;
                    }
                }
            });
        }

        return validatorResult.valid;
    };

    const initForm = (formElement: Element): void => {
        formElement.addEventListener('submit', event => {
            removeAllMessages(formElement);

            const validateResult = validateForm(event.target as Element);

            if (!validateResult) {
                event.preventDefault();
                return false;
            }

            const formatElements: Element[] = [...formElement.querySelectorAll('[data-format-submit]')];
            if (0 === formatElements.length) {
                return true;
            }
            formatElements.forEach(formatElement => {
                const formatType: string | null = formatElement.getAttribute('data-format-submit');
                if (null === formatType) {
                    return;
                }
                const value = (formatElement as HTMLInputElement).value;
                (formatElement as HTMLInputElement).value = formatter.formatter.formatField(value, formatType);
            });
        });
    };

    const initForms = (): void => {
        const formsElements: Element[] = [...document.querySelectorAll('.jsFormValidator')];
        const formWatchedElements: Element[] = [...document.querySelectorAll('[data-validate-watch="1"]')];

        if (0 === formsElements.length) {
            return;
        }

        formsElements.forEach((formElement: Element) => {
            initForm(formElement);
        });

        if (0 === formWatchedElements.length) {
            return;
        }

        formWatchedElements.forEach((formWatchedElement: Element) => {
            checkInputInit(formWatchedElement);
        });
    };

    const removeAllMessages = (form: Element): void => {
        const messagesElements = [...form.querySelectorAll('.-error')];

        messagesElements.forEach(messagesElement => {
            validator.removeInputMessages(messagesElement);
        });
    };

    const processAjaxValidatorData = (data: Array<AjaxValidatorItem>, formId: string | null): void => {
        const form = document.querySelector(`form[data-form-id="${formId}"]`);

        if (null === form || 0 === data.length) {
            return;
        }

        data.forEach((fieldData, index) => {
            let element: null | Element = form.querySelector(`[name="${fieldData.fieldId}"]`);

            if (null === element) {
                element = form.querySelector(`#${fieldData.fieldId}`);
            }
            if (null === element) {
                return;
            }
            if ('hidden' === element.getAttribute('type')) {
                element = form.querySelector(`[data-name="${fieldData.fieldId}"]`);
            }
            if (null === element) {
                return;
            }

            if (0 === index) {
                element.scrollIntoView();
            }

            validator.insertInputError(element, fieldData.message);
        });
    };

    return {
        initForms,
        validateForm,
        processAjaxValidatorData,
    };
};

const validator = Validator();

export { validator };

document.addEventListener('DOMContentLoaded', () => {
    validator.initForms();
});
