import { confirmAlert } from 'react-confirm-alert';

class Confirm {
    public static init = (): void => {
        const buttons: Element[] = [...document.querySelectorAll('.jsConfirmDialog')];

        buttons.map(button => {
            button.addEventListener('click', event => {
                if (button.classList.contains('clicked')) {
                    return true;
                }

                event.preventDefault();

                const message = button.getAttribute('data-confirm-message');
                const yes = button.getAttribute('data-confirm-yes');
                const no = button.getAttribute('data-confirm-no');

                confirmAlert({
                    title: null === message ? 'Jste si jistý?' : message,
                    message: '',
                    buttons: [
                        {
                            label: null === yes ? 'Ano' : yes,
                            onClick: () => {
                                button.classList.add('clicked');
                                (button as HTMLButtonElement).click();
                            },
                        },
                        {
                            label: null === no ? 'Ne' : no,
                            onClick: () => {},
                        },
                    ],
                });
            });
        });
    };
}

export default Confirm;
