import React from 'react';
import { randomString } from '~utils/string';

interface SelectTableRowProps {
    id: string;
    checked: boolean;
    onToggle: (id: string) => void;
}

const SelectTableRow: React.FC<SelectTableRowProps> = props => {
    const randomId = randomString(10);
    return (
        <div className="checkbox__cell -invisible-label">
            <input
                type="checkbox"
                id={'check_' + props.id + randomId}
                name="checked"
                checked={props.checked}
                onChange={() => {
                    props.onToggle(props.id);
                }}
            />
            <label htmlFor={'check_' + props.id + randomId} className="form__label" title="Vybrat">
                <span className="_visually-hidden">{'checkAll' === props.id ? 'Vybrat vše' : 'Vybrat'}</span>
            </label>
        </div>
    );
};

export default SelectTableRow;
