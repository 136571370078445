import iSet from '~react/components/FilterList/utils/icons.json';
import React from 'react';
import { BasicObject } from '~components/ObjectHelper';

interface IconProps {
    type: string | undefined | null;
}

const Icon: React.FC<IconProps> = props => {
    if (undefined === props.type || null === props.type || 'undefined' === typeof (iSet as BasicObject)[props.type]) {
        return null;
    }

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: (iSet as BasicObject)[props.type] as string,
            }}
        ></span>
    );
};

export default Icon;
