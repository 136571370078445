import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import Loader from '~react/components/FilterList/components/Loader';
import EventHelper from '~components/EventHelper';
import axios from 'axios';
import { z } from 'zod';

interface ListModalProps {
    sourceURL: string;
    content?: string;
    isOpen: boolean;
    onClose?: (reload?: boolean) => void;
    container?: Element;
    afterOpened?: () => void;
}

const sourceDataSchema = z.string();

const ListModal: React.FC<ListModalProps> = props => {
    const [sourceHTML, setSourceHTML] = useState<string>(props.content ?? '');
    const [open, setOpen] = useState<boolean>(props.isOpen);

    useEffect(() => {
        if ('' === props.sourceURL) {
            return;
        }

        setSourceHTML('');

        axios.get(props.sourceURL).then(response => {
            const html = sourceDataSchema.parse(response.data);

            setSourceHTML(html);

            EventHelper.dispatch('modalOpenEnd');
        });
    }, [props.sourceURL]);

    useEffect(() => {
        if ('' === sourceHTML) {
            return;
        }
        // close button event listeners in modal content
        // setTimeout delays it to next call of event loop to ensure modal content is already rendered
        setTimeout(() => {
            const closeButtons: NodeListOf<Element> = document.querySelectorAll('[data-filterlist-modal-close]');
            closeButtons.forEach(closeButton => {
                closeButton.addEventListener('click', event => {
                    event.preventDefault();
                    onClose();
                });
            });
        });
    }, [sourceHTML]);

    const getReload = (): boolean => {
        const onModalClose = document.querySelector('[data-grid-reload-on-modal-close]');

        return null !== onModalClose;
    };

    const onClose = () => {
        if (undefined === props.onClose) {
            setOpen(false);
        } else {
            props.onClose(getReload());
        }
        EventHelper.dispatch('modalCloseEnd');
    };

    const onAnimationEnd = () => {
        if (open && props.afterOpened !== undefined) {
            props.afterOpened();
        }
    };

    return (
        <Modal
            open={undefined === props.onClose ? open : props.isOpen}
            onClose={onClose}
            container={props.container ?? null}
            onAnimationEnd={onAnimationEnd}
        >
            {'' === sourceHTML ? <Loader /> : <div dangerouslySetInnerHTML={{ __html: sourceHTML }} />}
        </Modal>
    );
};

export default ListModal;
