const getLocaleFromHtml = (): 'en' | 'cs' => {
    const defaultLocale = 'en';
    const htmlElement = document.getElementsByTagName('html').item(0);
    if (null === htmlElement) {
        return defaultLocale;
    }
    return (htmlElement.getAttribute('lang') as 'en' | 'cs') ?? defaultLocale;
};

export { getLocaleFromHtml };
