const localizeMonth = {
    en: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    cs: [
        'Leden',
        'Únor',
        'Březen',
        'Duben',
        'Květen',
        'Červen',
        'Červenec',
        'Srpen',
        'Září',
        'Říjen',
        'Listopad',
        'Prosinec',
    ],
};

const getFormattedIsoMonthDate = (date: Date): string => {
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}`;
};

const getFormattedDate = (date: Date): string => {
    return (
        date
            .getFullYear()
            .toString()
            .padStart(4, '0') +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date
            .getDate()
            .toString()
            .padStart(2, '0')
    );
};

const getStandardFormattedDate = (date: Date): string => {
    return (
        date
            .getDate()
            .toString()
            .padStart(2, '0') +
        '.' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '.' +
        date
            .getFullYear()
            .toString()
            .padStart(4, '0')
    );
};

const getFormattedTime = (date: Date): string => {
    return (
        date
            .getHours()
            .toString()
            .padStart(2, '0') +
        ':' +
        date
            .getMinutes()
            .toString()
            .padStart(2, '0') +
        ':' +
        date
            .getSeconds()
            .toString()
            .padStart(2, '0')
    );
};

export { localizeMonth, getFormattedDate, getStandardFormattedDate, getFormattedTime, getFormattedIsoMonthDate };
