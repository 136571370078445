import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '~react/components/FilterList/hooks';

interface FilterTextInputProps {
    id: string;
    defaultValue: string;
    onChange: (value: string) => void;
}

const FilterTextInput: React.FC<FilterTextInputProps> = props => {
    const [value, setValue] = useState<string>(props.defaultValue);

    const handleChange = useCallback(event => setValue(event.currentTarget.value), [setValue]);

    const debouncedValue = useDebounce(value, 500);

    useEffect(() => {
        if (debouncedValue !== props.defaultValue) {
            props.onChange(debouncedValue);
        }
    }, [props.defaultValue, props.onChange, debouncedValue]);

    return <input type="text" id={props.id} defaultValue={props.defaultValue} onChange={handleChange} />;
};

export default FilterTextInput;
