// TODO generalize for all forms with captcha but without ajaxification
const LoginForm = (): { loginFormInit: () => void } => {
    const loginFormInit = (): void => {
        console.info('initializing login form');
        const loginForm = document.querySelector<HTMLFormElement>('#login-form');
        if (null === loginForm) {
            console.warn('no login form found');
            return;
        }
        loginForm.addEventListener(
            'submit',
            async (event): Promise<void> => {
                console.info('login form submitted');
                // stop submitting
                event.preventDefault();
                // resubmit form
                loginForm.submit();
            },
        );
    };

    return {
        loginFormInit,
    };
};

export default LoginForm;
