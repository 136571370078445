import '../../styles/style.scss';
import '../../scripts/components/modal';
import AjaxForm from '~components/AjaxForm';
import LoginForm from '~components/LoginForm';
import BurgerMenu from '~components/BurgerMenu';
import Tabs from '~components/Tabs';
import modal from '~components/Modal';
import InitFilterList from '~components/FilterList';
import Confirm from '~components/Confirm';
import filterFormInit from '~components/FilterForm';
import initMultipleFileInput from '~components/MultipleFileInput';

const ajaxForm = AjaxForm();

document.addEventListener('DOMContentLoaded', () => {
    const burgerMenu = BurgerMenu();
    const tabs = new Tabs();

    ajaxForm.init();
    LoginForm().loginFormInit();
    burgerMenu.init();
    tabs.initTabs();
    modal.modalInit();
    InitFilterList();
    Confirm.init();
    filterFormInit();
    initMultipleFileInput();
});

document.addEventListener('modalOpenEnd', () => {
    ajaxForm.init();
});
