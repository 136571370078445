import { render } from 'react-dom';
import MultipleFileInput from '../react/atoms/MultipleFileInput';
import React from 'react';
import { getJsonDataFromDataAttribute } from '~utils/json';

const initMultipleFileInput = () => {
    const elements: Element[] = [...document.querySelectorAll('.js_multipleFileInput')];

    if (0 === elements.length) {
        return;
    }

    elements.map(element => {
        render(
            <MultipleFileInput
                texts={getJsonDataFromDataAttribute(element.getAttribute('data-texts'))}
                accept={element.getAttribute('data-accept')}
                required={element.hasAttribute('data-required')}
            />,
            element,
        );
    });
};

export default initMultipleFileInput;
