import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import cs from 'date-fns/locale/cs';
import enUS from 'date-fns/locale/en-US';
import { getFormattedDate } from '~utils/date';

registerLocale('cs', cs);
registerLocale('en', enUS);

interface DateSelectProps {
    value: string;
    name?: string;
    id: string;
    label?: string;
    onDateChange?: (date: Date | null) => void;
    container?: boolean;
    locale?: string;
    required?: boolean;
    texts: {
        calendar: string;
    };
}

const DateSelect: React.FC<DateSelectProps> = props => {
    const picker = React.useRef<DatePicker>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<null | Date>('' === props.value ? null : new Date(Date.parse(props.value)));
    const [zIndex, setZIndex] = useState({ zIndex: 999 });

    useEffect(() => {
        picker.current?.setOpen(isOpen);
    }, [isOpen]);

    const onDateChange = (date: Date) => {
        if (undefined === props.onDateChange) {
            setValue(date);
            return;
        }
        props.onDateChange(date);
    };

    const getValue = (): null | Date | undefined => {
        if (undefined === props.onDateChange) {
            if (null === value) {
                return undefined;
            }

            return value;
        }

        if ('' === props.value) {
            return undefined;
        }

        return new Date(Date.parse(props.value));
    };

    const getIsoValue = (): string => {
        const date = getValue();
        if (null === date || undefined === date) {
            return '';
        }
        return getFormattedDate(date);
    };

    return (
        <div
            className={props.container ? 'form__cell -input-with-button' : undefined}
            style={props.container && isOpen ? zIndex : undefined}
        >
            <label className="form__label" htmlFor={props.id + '_inner'}>
                {props.label}
            </label>
            <input type="hidden" name={props.name} id={props.id} value={getIsoValue()} />
            <DatePicker
                id={props.id + '_inner'}
                className="-full-input -with-button"
                calendarStartDay={1}
                selected={getValue()}
                onChange={onDateChange}
                ref={picker}
                locale={props.locale ?? 'en'}
                dateFormat="dd.MM.yyyy"
                onBlur={() => {
                    setIsOpen(false);
                }}
                onFocus={() => {
                    setIsOpen(true);
                }}
                onCalendarOpen={() => {
                    setZIndex({ zIndex: 999 });
                }}
                onCalendarClose={() => {
                    setZIndex({ zIndex: 0 });
                }}
                required={props.required}
                autoComplete="off"
            />
            <button
                className="btn -link -with-icon"
                onClick={() => {
                    picker.current?.setOpen(!picker.current?.isCalendarOpen());
                    setIsOpen(!isOpen);
                }}
                type="button"
            >
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 34">
                    <title>{props.texts.calendar}</title>
                    <path d="M26.446 6.369h-2.715v-2.162c-0.003-0.623-0.502-1.123-1.125-1.126h-3.099c-0.624 0.003-1.123 0.502-1.126 1.126v2.162h-7.905v-2.162c-0.002-0.623-0.502-1.123-1.125-1.126h-3.098c-0.624 0.003-1.123 0.502-1.126 1.126v2.162h-2.822c-1.136 0.002-2.055 0.921-2.057 2.056v17.778c0.002 1.135 0.921 2.054 2.057 2.056h24.14c1.136-0.002 2.054-0.921 2.056-2.056v-17.778c-0.002-1.135-0.92-2.054-2.056-2.056zM19.701 4.402h2.711v5.36h-2.711v-5.36zM6.448 4.402h2.709v5.36h-2.709v-5.36zM26.966 26.723c-0.137 0.135-0.317 0.217-0.52 0.217h-24.14c-0.202 0-0.384-0.082-0.52-0.217-0.135-0.137-0.216-0.317-0.217-0.52v-17.778c0.001-0.202 0.082-0.383 0.217-0.52 0.137-0.136 0.318-0.216 0.52-0.216h2.822v2.267c0.002 0.623 0.502 1.123 1.126 1.126h3.098c0.623-0.003 1.123-0.502 1.125-1.126v-2.267h7.905v2.267c0.003 0.623 0.502 1.123 1.126 1.126h3.099c0.623-0.003 1.122-0.502 1.125-1.126v-2.267h2.715c0.202 0 0.383 0.080 0.52 0.216s0.217 0.318 0.217 0.52v17.778c0 0.202-0.081 0.383-0.217 0.52zM16.657 13.981h-12.641c-0.623 0.003-1.123 0.502-1.125 1.126v8.944c0.002 0.623 0.502 1.123 1.125 1.126h12.641c0.623-0.003 1.123-0.502 1.125-1.126v-8.944c-0.002-0.623-0.502-1.123-1.125-1.126zM16.462 23.857h-12.253v-8.555h12.253v8.555zM24.526 13.981h-4.498c-0.624 0.003-1.123 0.502-1.126 1.126v2.66c0.002 0.623 0.502 1.123 1.126 1.125h4.498c0.623-0.002 1.123-0.502 1.125-1.125v-2.66c-0.002-0.623-0.502-1.123-1.125-1.126zM24.331 17.573h-4.11v-2.271h4.11v2.271zM24.526 20.487h-4.498c-0.624 0.003-1.123 0.502-1.126 1.126v2.76c0.002 0.623 0.502 1.123 1.126 1.126h4.498c0.623-0.003 1.123-0.502 1.125-1.126v-2.76c-0.002-0.623-0.502-1.123-1.125-1.126zM24.331 24.179h-4.11v-2.372h4.11v2.372z" />
                </svg>
                <span>{props.texts.calendar}</span>
            </button>
        </div>
    );
};

export default DateSelect;
