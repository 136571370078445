import React from 'react';

interface SortingTableLabelProps {
    label: string;
    column: string;
    onSort: (by: string, type: string) => void;
    texts: {
        asc: string;
        desc: string;
    };
}

const SortingTableLabel: React.FC<SortingTableLabelProps> = props => {
    return (
        <div className="sorting-wrapper">
            <span>{props.label}</span>
            <span className="sorting">
                <span
                    className="sorting__trigger -up"
                    role="button"
                    onClick={() => {
                        props.onSort(props.column, 'asc');
                    }}
                >
                    <span className="_visually-hidden">{props.texts.asc}</span>
                </span>
                <span
                    className="sorting__trigger -down"
                    role="button"
                    onClick={() => {
                        props.onSort(props.column, 'desc');
                    }}
                >
                    <span className="_visually-hidden">{props.texts.desc}</span>
                </span>
            </span>
        </div>
    );
};

export default SortingTableLabel;
