const getJsonDataFromDataAttribute = (text: string | null) => {
    const items: string = null === text ? '[]' : text;
    let itemsData;

    try {
        itemsData = JSON.parse(items);
    } catch (e) {
        itemsData = [];
    }

    return itemsData;
};

export { getJsonDataFromDataAttribute };
