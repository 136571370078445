class EventHelper {
    static dispatch = (eventName: string): Event => {
        let event;
        if ('function' === typeof Event) {
            event = new Event(eventName, { bubbles: true, cancelable: true });
        } else {
            event = document.createEvent('Event');
            event.initEvent(eventName, true, true);
        }
        document.dispatchEvent(event);

        return event;
    };
}

export default EventHelper;
