import React from 'react';
import ListTable from '~react/components/FilterList/components/ListTable';
import ListPager from '~react/components/FilterList/components/ListPager';
import ListActions from '~react/components/FilterList/components/ListActions';
import Loader from '~react/components/FilterList/components/Loader';
import ListInfo from '~react/components/FilterList/components/ListInfo';
import Message from '~react/components/FilterList/components/Message';
import { AjaxHandlerResponse, AjaxHandlerResponseError } from '~components/AjaxHandler';
import useFilterList from '~react/components/FilterList/state';
import Filter from '~react/components/FilterList/components/Filter';
import { FilterListConfigInterface } from '~types/FilterListConfigInterface';
import ListModal from '../../atoms/ListModal';

interface FilterListProps {
    config: FilterListConfigInterface;
}

const FilterList: React.FC<FilterListProps> = props => {
    const {
        state,
        filter,
        message,
        setMessage,
        setItemsIsLoaded,
        setPage,
        setPerPage,
        setSort,
        loadItemsData,
        toggleSelect,
        isItemSelected,
        getItemId,
        onModalClose,
        onModalOpen,
        onFilterInputChange,
    } = useFilterList(props.config);

    const hideFlashMessages = (): void => {
        const flashMessages: Element[] = [...document.querySelectorAll('.flashMessage')];

        if (0 === flashMessages.length) {
            return;
        }

        flashMessages.forEach(e => {
            e.classList.add('d-none');
        });
    };

    // list actions
    const listActionsHandleSuccess = (response: AjaxHandlerResponse) => {
        if ('redirect' === response.type && response.redirectUrl) {
            if (window.location.href.includes(response.redirectUrl)) {
                window.location.reload();
                return;
            }
            window.location.href = response.redirectUrl;
            return;
        }
        hideFlashMessages();
        setMessage(response, 'success');
        loadItemsData();
    };

    const listActionsHandleError = (response: AjaxHandlerResponseError) => {
        setMessage(response, 'error');
        setItemsIsLoaded(true);
    };

    const listActionsBeforePost = () => {
        setItemsIsLoaded(false);
    };

    return (
        <div className={state.config.data.prefix + 'filterList'}>
            <Message messageType={message.type} message={message.message} />
            {state.config.isLoaded ? (
                <>
                    <Filter
                        cells={null === state.config.data.filters ? [] : state.config.data.filters}
                        onChange={onFilterInputChange}
                        values={filter}
                        pager={state.config.data.pager}
                        onPerPageChange={setPerPage}
                    />
                    <ListActions
                        exclude={['create']}
                        group={true}
                        handleSuccess={listActionsHandleSuccess}
                        handleError={listActionsHandleError}
                        postData={{ [state.config.data.selected + 's']: state.selected }}
                        disabled={0 === state.selected.length}
                        texts={{ confirm: state.config.data.translations.confirm }}
                        actions={state.config.data.listActions}
                        beforePost={listActionsBeforePost}
                        openModal={onModalOpen}
                        filter={filter}
                    />
                    <ListActions
                        include={['create']}
                        buttonType="link"
                        handleSuccess={listActionsHandleSuccess}
                        handleError={listActionsHandleError}
                        postData={{ [state.config.data.selected + 's']: state.selected }}
                        disabled={0 === state.selected.length}
                        texts={{ confirm: state.config.data.translations.confirm }}
                        actions={state.config.data.listActions}
                        group={false}
                        openModal={onModalOpen}
                        filter={filter}
                    />
                    {state.items.isLoaded ? (
                        <ListTable
                            items={state.items.data}
                            footer={state.items.footer}
                            columns={state.config.data.columns}
                            onSort={setSort}
                            hasSelected={
                                'undefined' !== typeof state.config.data.selected && state.config.data.selected !== null
                            }
                            onSelectToggle={toggleSelect}
                            isItemSelected={isItemSelected}
                            getItemId={getItemId}
                            texts={{
                                asc: state.config.data.translations.asc,
                                desc: state.config.data.translations.desc,
                                confirm: state.config.data.translations.confirm,
                            }}
                            handleSuccess={listActionsHandleSuccess}
                            handleError={listActionsHandleError}
                            openModal={onModalOpen}
                        />
                    ) : (
                        <Loader />
                    )}
                    <ListInfo
                        message={
                            state.items.isLoaded && 0 === state.items.data.length
                                ? state.config.data.translations.empty
                                : ''
                        }
                    />
                    <ListPager
                        hasNext={state.items.hasNext}
                        setPage={setPage}
                        page={filter.page}
                        texts={{
                            prevLabel: null !== state.config.data.pager ? state.config.data.pager.prevLabel : '',
                            nextLabel: null !== state.config.data.pager ? state.config.data.pager.nextLabel : '',
                        }}
                    />
                    <ListActions
                        include={['create']}
                        buttonType="link"
                        handleSuccess={listActionsHandleSuccess}
                        handleError={listActionsHandleError}
                        postData={{ [state.config.data.selected + 's']: state.selected }}
                        disabled={0 === state.selected.length}
                        texts={{ confirm: state.config.data.translations.confirm }}
                        actions={state.config.data.listActions}
                        group={false}
                        openModal={onModalOpen}
                        filter={filter}
                    />
                    <ListModal sourceURL={state.modalURL} isOpen={'' !== state.modalURL} onClose={onModalClose} />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default FilterList;
